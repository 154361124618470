/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import 'bootstrap-datepicker';
import 'eonasdan-bootstrap-datetimepicker';
import { fr } from 'date-fns';
import $ from 'jquery';
import '../js/collectionHolder.js';
import '../js/tableActions.js';
import '../js/modalEditObject.js';
import '../js/editPicture.js';
import '../js/jquery.fn.gantt';
import '../js/tabDate.js';
import '../js/TimeLine.js';
import '../js/ModalLetterType.js';
import '../js/jquery.radios-to-slider.js';
import '@fortawesome/fontawesome-free/js/all';

require('jquery/dist/jquery.min');
require('jquery-ui/ui/widgets/tooltip');
// require('jquery-ui/ui/widgets/tabs');
require('popper.js/dist/popper.min');
require('bootstrap/dist/js/bootstrap.min');
require('bootstrap-daterangepicker/daterangepicker');
require('bootstrap-table/dist/bootstrap-table.min');
require('admin-lte/dist/js/adminlte.min');
require('tableexport.jquery.plugin/libs/jsPDF/jspdf.min');
require('tableexport.jquery.plugin/libs/jsPDF-AutoTable/jspdf.plugin.autotable');
require('tableexport.jquery.plugin/tableExport.min');
require('bootstrap-table/dist/extensions/export/bootstrap-table-export.min');
require('bootstrap-table/dist/bootstrap-table-locale-all');
require('bootstrap-table/dist/extensions/editable/bootstrap-table-editable.min');
require('bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control.min');
require('jquery-toast-plugin/dist/jquery.toast.min');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');
require('bootstrap-toggle/js/bootstrap-toggle.min');
require('admin-lte/plugins/iCheck/icheck.min');
require('bootstrap-select/dist/js/bootstrap-select.min');
require('@fortawesome/fontawesome-free/js/all');
require('moment/min/locales.min');
require('chart.js/dist/Chart.min');
require('disableautofill/src/jquery.disableAutoFill.min');

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

$(function () {
    'use strict';

    var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
    jQuery.htmlPrefilter = function( html ) {
        return html.replace( rxhtmlTag, "<$1></$2>" );
    };

    moment.locale('fr');

    $.extend($.fn.bootstrapTable.defaults, $.fn.bootstrapTable.locales['fr-FR']);

    $('.datepicker').datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        language: 'fr'
    });

    $('.datetimepicker').datetimepicker({
        locale: 'fr'
    });

    // $('input').each(function () {
    //     if (!$(this).parents('.radios-to-slider').length) {
    //         $(this).iCheck({
    //             checkboxClass: 'icheckbox_flat-orange',
    //             radioClass: 'iradio_square',
    //             increaseArea: '20%' // optional
    //         });
    //     }
    // });

    $('[data-toggle="tooltip"]').tooltip();

    // On garde en mémoire le dernier onglet visité en mode édition
    var $tabActive = $('form').find('li.active').find('a[data-toggle="tab"]');

    if ($tabActive.length) {
        setTabForm($tabActive);
    }

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        setTabForm($(e.target));
    });

    //On désactive la saisie automatique du navigateur.
    // $('form').attr('autocomplete', 'off');
    // $('form').disableAutoFill({
    //     randomizeInputName: false
    // });

});

function setTabForm($tab) {
    var target = parseInt($tab.attr("href").split('_')[2]); // activated tab
    // Si on est en mode edit
    if($(location).attr('href').indexOf('edit') != -1 && target){

        var tab = $(location).attr('href').split('edit')[1];
        var url = $(location).attr('href') + '/' + target;

        if (tab) {
            url = $(location).attr('href').split('edit')[0] + 'edit/' + target
        }

        $('a[data-toggle="tab"]').parents('form').attr('action', url);
    }
}

window.pushToast = function (type, text, messageHead) {
    if (messageHead == undefined)
    {
        switch (type) {
            case 'success':
                messageHead = 'Parfait !';
                break;
            case 'error':
                messageHead = 'Une erreur est survenue';
                break
        }
    }

    $.toast({
        text: text,
        heading: messageHead,
        icon: type,
        showHideTransition: 'slide',
        allowToastClose: true,
        hideAfter: 3000,
        stack: 5,
        position: 'top-center',
        textAlign: 'left',
        loader: true,
        loaderBg: '#9EC600',
    });
};

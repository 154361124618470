'use strict';

// import $ from "jquery";

$.fn.ModalLetterType = function (config) {
    let $modalLetterType = this;

    this.$modal = false;
    this.$contentModal = false;

    this.configDefault = {
        key: false,
        urlAjax: false,
        view: false,
        modal: {
            title: 'Lettre type'
        }
    };

    /**
     * On initialise les données. :)
     */
    this.init = function () {
        this.initConfig();

        // Si la modal n'existe pas on la créer.
        if (!this.$modal) {
            this.$modal = this.createModal();
        }

        this.loadEvent(); // On charge les évènements.
    };

    this.initConfig = function () {
        this.config = config !== undefined ? {
            key: config.key !== undefined ? config.key : this.configDefault.key,
            urlAjax: config.urlAjax !== undefined ? config.urlAjax : this.configDefault.urlAjax,
            view: config.view !== undefined ? config.view : this.configDefault.view,
            modal: config.modal !== undefined ? config.modal : this.configDefault.modal,
        } : this.configDefault;

        if (this.config.view) {
            this.config.view.nameFile = this.config.view.nameFile !== undefined ? this.config.view.nameFile : 'visualiser';
            this.config.view.dataAjaxView = this.config.view.dataAjaxView !== undefined ? this.config.view.dataAjaxView : {};
        }

        this.config.modal.title = this.config.modal.title !== undefined ? this.config.modal.title : this.configDefault.modal.title;
    };

    this.checkError = function () {
        let messageError = false;

        if (!this.config.key) {
            messageError = 'Aucune clef renseignée dans les paramètres de la modal...';
        }

        if (!this.config.urlAjax) {
            messageError = 'Aucune url pour l\'ajax a été renseignée dans les paramètres de la modal...';
        }

        if (messageError) {
            this.addError(messageError);

            return true;
        }

        return false;
    };

    this.loadEvent = function () {
        // On intercepte l'evenement du click pour ouvrir la modal.
        $modalLetterType.on('click', function (e) {
            e.preventDefault();

            // Si la config est bonne alors on charge la modal.
            if (!$modalLetterType.checkError()) {
                $modalLetterType.loadContent();
                $modalLetterType.$modal.modal('toggle');
            } else {
                console.error("Une erreur est survenue...");
            }
        });
    };

    this.loadEventContentModal = function () {

        // On génère le pdf en ajax.
        $modalLetterType.$modal.find('#btn_generate').unbind('click');
        $modalLetterType.$modal.find('#btn_generate').on('click', function (e) {
            var contentPdf = CKEDITOR.instances[$modalLetterType.$contentModal.find('textarea').attr('id')].getData();

            e.preventDefault();

            $.ajax({
                method: "POST",
                url: $modalLetterType.config.urlAjax,
                data: {
                    'contentPdf' : CKEDITOR.instances[$modalLetterType.$contentModal.find('textarea').attr('id')].getData(),
                    'letterTypeKey': $modalLetterType.config.key,
                    'nameFile': $modalLetterType.config.view.nameFile
                },
                async: false
            }).done(function( data ) {
                if (data.error) {
                    pushToast('error', data.message);
                } else {
                    pushToast('success', data.message);
                }
            });
        });

        if (this.config.view) {
            var $btnView = $modalLetterType.$modal.find('#btn_view');

            $btnView.on('click', function (e) {
                e.preventDefault();
                var $btnViewDownload = $modalLetterType.$modal.find('#btn_view_download');

                $.ajax({
                    method: "POST",
                    url: '/admin/lettertype/downloadFile',
                    data: $.extend(true, $modalLetterType.config.view.dataAjaxView, {
                        'contentPdf':CKEDITOR.instances[$modalLetterType.$contentModal.find('textarea').attr('id')].getData(),
                        'letterTypeKey': $modalLetterType.config.key,
                        'nameFile': $modalLetterType.config.view.nameFile
                    } ),
                    async: false
                }).done(function( data ) {
                    if (data.error) {
                        pushToast('error', data.message);
                    } else {
                        $btnViewDownload.attr('href', "data:application/pdf;base64,"+ data.pdf);
                        $btnViewDownload.attr('download', $modalLetterType.config.view.nameFile + '.pdf');
                        $btnViewDownload[0].click();
                    }
                });
            });

        }
    };

    /**
     * On change le contenu de la modal en fonction de la key donnée en config.
     */
    this.loadContent = function () {
      $.ajax({
          method: "POST",
          url: '/admin/lettertype/getLetterType',
          data: { key: this.config.key },
          async: false
      }).done(function( data ) {
          if (!data.error) {
              $modalLetterType.$contentModal.html(data.content);
              $modalLetterType.loadEventContentModal(); // On génère les évenements pour le contenu de la modal.
          } else {
            $modalLetterType.addError(data.message);
          }
      });
    };

    /**
     * Création de la modal.
     * @returns {jQuery|HTMLElement}
     */
    this.createModal = function () {
        var modal = document.createElement('div');
        modal.id = 'modalLetterType';
        modal.className = 'modal fade';
        var attrTabIndex = document.createAttribute('tabindex');
        var attrRole = document.createAttribute('role');
        var attrLabelleby = document.createAttribute('aria-labelledby');
        var attrAriaHidden = document.createAttribute('aria-hidden');
        attrTabIndex.value = '-1';
        attrRole.value = 'dialog';
        attrLabelleby.value = 'modalLetterType';
        attrAriaHidden.value = 'true';
        modal.setAttributeNode(attrTabIndex);
        modal.setAttributeNode(attrRole);
        modal.setAttributeNode(attrLabelleby);
        modal.setAttributeNode(attrAriaHidden);

        var modalDialog = document.createElement('div');
        modalDialog.className = 'modal-dialog modal-lg';

        var modalContent = document.createElement('div');
        modalContent.className = 'modal-content';

        var modalHeader = document.createElement('div');
        modalHeader.className = 'modal-header';

        var h5 = document.createElement('h5');
        h5.className = 'modal-title';
        h5.id = 'modalLetterType';
        var textH5 = document.createTextNode($modalLetterType.config.modal.title);
        h5.appendChild(textH5);

        var modalBody = document.createElement('div');
        modalBody.className = 'modal-body';

        var divSpinner = document.createElement('div');
        divSpinner.style.textAlign = 'center';

        var spinner = document.createElement('img');
        spinner.src = '/img/spinner.gif';
        spinner.className = 'rounded';
        spinner.style.height = '3em';

        this.$contentModal = $(modalBody);

        var modalFooter = document.createElement('div');
        modalFooter.className = 'modal-footer';

        if ($modalLetterType.config.view) {
            var buttonView = document.createElement('button');
            buttonView.type = 'button';
            buttonView.className = 'btn btn-warning';
            buttonView.id = 'btn_view';
            var textBtnView = document.createTextNode('Visualiser PDF');
            buttonView.appendChild(textBtnView);
            modalFooter.appendChild(buttonView);

            var buttonViewDownload = document.createElement('a');
            buttonViewDownload.id = 'btn_view_download';
            buttonViewDownload.target = '_blank';
            buttonViewDownload.style.display = 'none';
            modalFooter.appendChild(buttonViewDownload);
        }

        var buttonSuccess = document.createElement('button');
        buttonSuccess.type = 'button';
        buttonSuccess.className = 'btn btn-success';
        buttonSuccess.id = 'btn_generate';
        buttonSuccess.style.marginLeft = '5px';
        var textBtnSuccess = document.createTextNode('Générer PDF');
        buttonSuccess.appendChild(textBtnSuccess);

        var buttonClose = document.createElement('button');
        buttonClose.type = 'button';
        buttonClose.className = 'btn btn-secondary';
        var textBtnClose = document.createTextNode('Fermer');
        var attrDismiss = document.createAttribute('data-dismiss');
        attrDismiss.value = 'modal';
        buttonClose.setAttributeNode(attrDismiss);
        buttonClose.appendChild(textBtnClose);

        modalFooter.appendChild(buttonSuccess);
        modalFooter.appendChild(buttonClose);
        divSpinner.appendChild(spinner);
        modalBody.appendChild(divSpinner);
        modalHeader.appendChild(h5);
        modalContent.appendChild(modalHeader);
        modalContent.appendChild(modalBody);
        modalContent.appendChild(modalFooter);
        modalDialog.appendChild(modalContent);
        modal.appendChild(modalDialog);

        document.querySelector('body').appendChild(modal);

        return $(modal);
    };

    /**
     * Mise en forme des erreurs au sein de la modal.
     * @param messageError
     */
    this.addError = function (messageError) {
        this.$contentModal.html('<div class="alert alert-danger alert-dismissible">\n' +
            '<h4><i class="icon fa fa-ban"></i> Erreur !</h4>\n' +
            messageError +
            '</div>');
    };

    if ($modalLetterType.length)
    {
        this.init();
    } else {
        console.error('Init fail of ModelLetterType. (element not found)');
    }
};
